
// import './App.css';
// import { BrowserRouter } from "react-router-dom";
// import MainRoute from './Route';

// function App() {
//   return (
//     <BrowserRouter>
//     <MainRoute/>
       
//     </BrowserRouter>
//     // <div className="App">
//     //   <UploadImages/>
//     //   <ListOfImages/>
//     //   <Login/>
//     // </div>
//   );
// }

// export default App;

import React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import MainRoute from './Route';
import { Box, Typography } from '@mui/material';
import sumeruoriginallogo from '../src/images/sumeru-logo-forLightTheam1.png';

function App() {
  return (
    <BrowserRouter>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: '100vh',
          overflow: 'hidden',
        }}
      >
        <MainRoute />

        {/* Watermark */}
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 16, sm: 24, md: 40 }, // Adjust spacing for different screen sizes
            right: { xs: 16, sm: 24, md: 40 },
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'auto', // Enable pointer events
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: { xs: '10px', sm: '12px', md: '14px' }, // Responsive font size
              color: '#333',
              marginRight: '8px',
              fontWeight: 'bold',
              opacity: 0.6, // Keep the text partially transparent
            }}
          >
            Developed by
          </Typography>
          <a
            href="https://sumerudigital.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Box
              component="img"
              src={sumeruoriginallogo}
              alt="Watermark"
              sx={{
                width: { xs: '180px', sm: '230px', md: '270px' },
                opacity: 1, // Make the image slightly brighter/focused
              }}
            />
          </a>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;   






